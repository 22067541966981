import React from "react";
import "./Contact.css";
import person1 from "../../../images/person.jpeg";
import person2 from "../../../images/person2.jpeg";
import person3 from "../../../images/person3.jpeg";

import { Link } from "react-router-dom";

function Contact() {
  return (
    <div className="faq_contact">
      <div className="faq_contact_container">
        <div className="upper">
          <div className="images">
            <img src={person1} alt="person" className="image1" />
            <img src={person2} alt="person" className="image2" />
            <img src={person3} alt="person" className="image3" />
          </div>
          <div className="text">
            <p className="title">Still have Questions?</p>
            <p className="desc">
              Can’t find the answer you’re looking for? Chat with our friendly
              team
            </p>
          </div>
        </div>

        <div className="button">
          <Link to="/contact" className="btn">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Contact;
