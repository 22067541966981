import React from "react";
import Hero from "../../components/Home/Hero/Hero";
import About from "../../components/Home/About/About";
import Partner from "../../components/Home/Partner/Partner";
import Newsletter from "../../components/Newsletter/Newsletter";
import Service from "../../components/Home/Service/Service";
import Program from "../../components/Home/Program/Program";

function Home() {
  return (
    <div>
      <Hero />
      <About />
      <Program />
      <Service />
      <Newsletter />
    </div>
  );
}

export default Home;
