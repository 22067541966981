import Partner from "../Partner/Partner";
import styles from "./Program.module.css";
function Program() {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <h1 className={styles.title}>President's message</h1>
        <div className={styles.image_container}>
          <img src="/team/President.jpg" alt="president" />
        </div>
        <p class={styles.desc}>
          Warm greetings from the beautiful city of Pokhara!
        </p>
        <p class={styles.desc}>
          I am Sundar Adhikari, honored to serve as the President of Dirghayu
          Nepal. My journey with this organization began with a heartfelt desire
          to make a meaningful difference in the lives of those in need. As a
          Clinical Pharmacist by profession, I bring both professional expertise
          and a deep commitment to humanitarian work to my role as President.
        </p>
        <p class={styles.desc}>
          The inception of Dirghayu Nepal dates back to April 2015, amidst the
          devastating aftermath of the Nepal Earthquake. Witnessing the immense
          need for support and assistance in earthquake-affected communities,
          our organization was born with a singular purpose: to extend a helping
          hand to those in crisis. Over time, our objectives have evolved and
          expanded, encompassing a broader spectrum of societal issues.
        </p>
        <p class={styles.desc}>
          Our focus areas include health, education, environment, and capacity
          development in Nepal. However, our primary concern remains the welfare
          of vulnerable groups, including children, the elderly, and individuals
          with disabilities. We are dedicated to fostering inclusive and
          equitable development, ensuring that no one is left behind.
        </p>
        <p class={styles.desc}>
          Dirghayu Nepal is honored to collaborate with esteemed National and
          International (Governmental and Non-Governmental) partners. These
          collaborations are instrumental in our collective efforts to empower
          communities, promote education, enhance healthcare, and foster
          sustainable development, making a tangible difference in the lives of
          individuals and communities we serve.
        </p>
        <p class={styles.desc}>
          We extend a heartfelt invitation to all like-minded individuals
          worldwide to join us in our noble endeavor of serving humanity.
          Whether through supporting, contributing, or actively participating in
          our projects, your involvement can make a meaningful difference in the
          lives of those we serve.
        </p>
        <p class={styles.desc}>
          Furthermore, we extend our heartfelt gratitude to all the helping
          hands that have generously contributed their time, resources, and
          support to Dirghayu Nepal, helping us make a positive impact in the
          lives of those we serve.
        </p>
        <p class={styles.desc}>
          Together, let us strive to create a world where compassion,
          solidarity, and opportunity are accessible to all.
        </p>
        <p class={styles.desc}>
          Warm Regards,
          <br />
          Sundar Adhikari
          <br />
          President, Dirghayu Nepal
        </p>
      </div>
      <div className={styles.right}>
        <Partner />
      </div>
    </div>
  );
}

export default Program;
