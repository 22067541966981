import React from "react";
import { motion } from "framer-motion";

import "./About.css";

function About() {
  return (
    <div id="about">
      <div className="about_header">
        <motion.h4
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="about_title"
        >
          Dirghayu Nepal
        </motion.h4>
        <motion.p
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="about_desc"
        >
          “To create a community that is socially and financially autonomous,
          with improved healthcare facilities, and that is educated and
          empowered.”
        </motion.p>
      </div>
    </div>
  );
}

export default About;
