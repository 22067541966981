import React, { useEffect, useRef } from "react";
// import Testimonial from "../../components/Aboutus/Testimonial/Testimonial";
import Service from "../../components/ServicePage/Service/Service";
import Hero from "../../components/ServicePage/Hero/Hero";
import { useParams } from "react-router-dom";

function ServicePage() {
  const reviewRef = useRef(null);

  const { id } = useParams();

  useEffect(() => {
    if (id === "review") {
      reviewRef.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
        inline: "end",
      });
    }
  }, [id]);
  return (
    <div>
      <Hero />
      <Service />
      <div ref={reviewRef}>{/* <Testimonial /> */}</div>
    </div>
  );
}

export default ServicePage;
