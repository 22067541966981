import React, { useEffect } from "react";
import Hero from "../../components/FAQ/Hero/Hero";
import Main from "../../components/FAQ/Main/Main";
import Contact from "../../components/FAQ/Contact/Contact";
import { useState } from "react";
import axios from "axios";

function FAQ() {
  const [searchTerm, setSearchTerm] = useState("");
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_DOMAIN}/faqs/${searchTerm}`)
      .then((res) => {
        setFaqs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchTerm]);

  return (
    <div>
      <Hero setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
      <Main searchTerm={searchTerm} faqs={faqs} />
      <Contact />
    </div>
  );
}

export default FAQ;
