import React from "react";
import { motion } from "framer-motion";

function Hero() {
  return (
    <div className="faq_hero common_faq challange">
      <div className="faq_hero_container">
        <div className="top">
          <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="title"
          >
            What we do
          </motion.p>
          <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="heading"
          >
            Welcome to Dirghayu Nepal
          </motion.p>
          <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.7 }}
            viewport={{ once: true }}
            className="desc"
          >
            The organization contributing its works to the improvement of public
            health, education, and environmental areas
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
