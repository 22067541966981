import React, { useState } from "react";
import { motion } from "framer-motion";

import "./Email.css";
import Dropdown from "../../Dropdown/Dropdown";

import axios from "axios";

function Email({ alert, setAlert }) {
  const [isLoading, setIsLoading] = useState(false);

  const [userEntry, setUserEntry] = useState({
    full_name: "",
    email: "",
    phone_no: "",
    contact_method: "Email",
    message: "",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setUserEntry({ ...userEntry, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      fullName: userEntry.full_name,
      emailAddress: userEntry.email,
      phoneNumber: userEntry.phone_no,
      contactMethod: userEntry.contact_method,
      message: userEntry.message,
    };
    axios
      .post(`${process.env.REACT_APP_SERVER_DOMAIN}/api/contact`, data)
      .then((res) => {
        setAlert({
          isOpen: true,
          message: "Message sent successfully",
          type: "success",
        });
        setUserEntry({
          full_name: "",
          email: "",
          phone_no: "",
          contact_method: "Email",
          message: "",
        });
      })
      .catch((err) => {
        setAlert({
          isOpen: true,
          message: "Something went wrong",
          type: "danger",
        });
      });
    setIsLoading(false);
    setTimeout(() => {
      setAlert({ ...alert, isOpen: false });
    }, 5000);
  };

  return (
    <motion.form
      whileInView={{ x: [-100, -50, 0], opacity: [0, 0, 1] }}
      transition={{ duration: 0.7 }}
      viewport={{ once: true }}
      className="contact_email"
      onSubmit={handleSubmit}
    >
      <div className="inputs_flex">
        <div className="inputs">
          <label htmlFor="full_name">Full Name</label>
          <input
            value={userEntry.full_name}
            onChange={handleChange}
            type="text"
            placeholder="Enter Your Full Name"
            name="full_name"
            required
          />
        </div>
        <div className="inputs">
          <label htmlFor="email">Email address</label>
          <input
            value={userEntry.email}
            onChange={handleChange}
            type="email"
            placeholder="Enter Your Email address"
            name="email"
            required
          />
        </div>
      </div>

      <div className="inputs_flex">
        <div className="inputs">
          <label htmlFor="phone_no">Phone Number</label>
          <input
            value={userEntry.phone_no}
            onChange={handleChange}
            type="text"
            placeholder="+000"
            name="phone_no"
          />
        </div>
        <div className="inputs select">
          <label htmlFor="contact_method">Contact method</label>
          <Dropdown
            options={["Email", "SMS"]}
            userEntry={userEntry}
            setUserEntry={setUserEntry}
          />
        </div>
      </div>
      <div className="inputs">
        <label htmlFor="message">Message</label>
        <textarea
          value={userEntry.message}
          onChange={handleChange}
          rows={8}
          type="text"
          placeholder="Hi! We are Dirghayu Nepal..."
          name="message"
          required
        />
      </div>

      <div className="submit_btn">
        <button disabled={isLoading} className="primary-btn" type="submit">
          Submit
        </button>
      </div>
    </motion.form>
  );
}

export default Email;
