import React from "react";
import { motion } from "framer-motion";

import "./Hero.css";

function Hero() {
  return (
    <div className="onboard_hero">
      <div className="onboard_hero_container">
        <motion.h2
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="onboard_title"
        >
          Our Programs
        </motion.h2>
        <motion.p
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="onboard_desc"
        >
          These are some programs conducted by our team.
        </motion.p>
      </div>
    </div>
  );
}

export default Hero;
