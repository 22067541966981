import React from "react";
import "./Main.css";

import image1 from "../../../images/whyus1.jpeg";
import image2 from "../../../images/whyus2.jpeg";

function Main() {
  return (
    <div className="whyus_main">
      <svg
        className="leftsvg"
        xmlns="http://www.w3.org/2000/svg"
        width="476"
        height="493"
        viewBox="0 0 476 493"
        fill="none"
      >
        <path
          d="M247.5 39.993C325.434 64.5198 408.343 89.9086 446.504 149.015C486.381 210.778 485.077 286.655 447.117 349.171C411.403 407.989 332.813 437.167 256.513 461.913C182.794 485.822 104.31 504.225 28.4532 484.955C-51.7304 464.586 -122.68 419.632 -153.758 357.69C-183.996 297.424 -156.765 232.551 -128.761 171.613C-99.8341 108.668 -71.5419 37.4185 5.86649 10.3402C83.3652 -16.7697 168.765 15.2141 247.5 39.993Z"
          fill="#02320A"
          fillOpacity="0.03"
        />
      </svg>
      <svg
        className="rightsvg"
        xmlns="http://www.w3.org/2000/svg"
        width="387"
        height="572"
        viewBox="0 0 387 572"
        fill="none"
      >
        <path
          d="M459.261 17.902C354.761 -38.098 -109.239 35.902 23.7615 259.402C156.761 482.902 23.761 507.902 223.261 561.902C422.762 615.902 678.761 428.902 684.761 309.902C690.761 190.902 563.762 73.902 459.261 17.902Z"
          fill="#02320A"
          fillOpacity="0.03"
        />
      </svg>
      <div className="whyus_main_container">
        <div className="upper">
          <div className="image">
            <img src={image1} alt="whyus" />
          </div>
          <div className="content">
            <div className="text">
              <p className="head">Why Gurkha Connection?</p>
              <p className="desc">
                To overcome the challenges and successfully promote yourself and
                your products in the international market it is importance to
                join the right platform. Here are some compelling reasons why
                joining Gurkha Connection can be beneficial for your business:
              </p>
            </div>
            <div className="text">
              <p className="title">
                Harness the Power of the Digital Marketplace:
              </p>
              <p className="desc">
                In today's digital era, the marketplace model has transformed
                the way businesses connect with customers. By leveraging digital
                platforms, you can expand your reach without the need for
                substantial investments in physical stores and warehouses.
                Gurkha Connection provides an easy onboarding process and
                efficient promotion assistance, enabling you to boost sales and
                effectively expand your reach.
              </p>
            </div>
            <div className="text">
              <p className="title">Expand Your Reach Globally:</p>
              <p className="desc">
                With Gurkha Connection's digital marketplace, you can transcend
                geographical boundaries and tap into a global customer base.
                Whether you have specific countries or demographics in mind, our
                platform offers the necessary tools and resources to help you
                connect with your desired audience. By joining Gurkha
                Connection, you gain access to a diverse range of customers
                actively seeking unique products and services.
              </p>
            </div>
          </div>
        </div>
        <div className="lower">
          <div className="content">
            <div className="text">
              <p className="title">
                Streamline Operations and Increase Efficiency:
              </p>
              <p className="desc">
                Managing operational requirements such as payments, shipping,
                refunds, returns, and product pickups can be time-consuming and
                resource-intensive for businesses. By partnering with Gurkha
                Connection, you can delegate these tasks to our platform,
                allowing you to focus on your core operations and enhance
                overall efficiency. We handle the logistics, enabling you to
                concentrate on what you do best.
              </p>
            </div>
            <div className="text">
              <p className="title">Gain Insights with Real-Time Analytics:</p>
              <p className="desc">
                Monitoring key performance indicators (KPIs) is vital for your
                business's success. However, developing an independent system to
                track revenue, sales, reach, and customer satisfaction can be
                challenging and costly. As a member of Gurkha Connection, you
                gain access to comprehensive dashboards providing real-time
                analytics. This empowers you with valuable insights into your
                business's performance, enabling you to make informed decisions
                and optimize your marketing strategies for better results.
              </p>
            </div>
            <div className="text">
              <p className="last">
                By joining Gurkha Connection, you become part of a passionate
                community dedicated to promoting brand Nepal and showcasing its
                beauty and heritage to the world. Together, we can overcome the
                challenges faced by Nepal's tourism industry and contribute to
                the country's economic growth. With the digital marketplace at
                your fingertips, Gurkha Connection will help you elevate your
                business to new heights.
              </p>
            </div>
          </div>
          <div className="image">
            <img src={image2} alt="whyus" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
