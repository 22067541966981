import React from "react";
import "./Hero.css";
import { motion } from "framer-motion";

function Hero() {
  return (
    <div className="faq_hero common_faq challange">
      <div className="faq_hero_container">
        <div className="top">
          {/* <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="title"
          >
            Mission
          </motion.p> */}
          <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.7 }}
            viewport={{ once: true }}
            className="heading"
          >
            Mission of Dirghayu Nepal
          </motion.p>
          <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="desc"
          >
            “To create a community that is socially and financially autonomous,
            with improved healthcare facilities, and that is educated and
            empowered.”
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
