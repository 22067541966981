import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../images/logo.jpg";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const [linksIsOpen, setLinksIsOpen] = useState(false);
  return (
    <div className="navbar">
      <div className={`navbar_container ${linksIsOpen ? "open" : ""}`}>
        <div className="navbar_overlay"></div>
        <Link
          to="/"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img src={logo} alt="logo" />
          <h3 style={{ color: "#ee1b22" }}>Dirghayu Nepal</h3>
        </Link>
        <div className="links_wrapper">
          <ul className="navbar_links">
            <li
              className={`mobile ${location.pathname === "/" ? "active" : ""}`}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/aboutus" ||
                location.pathname === "/aboutus/review"
                  ? "active"
                  : ""
              }`}
            >
              <Link to="/aboutus">About</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/our-team" ? "active" : ""
              }`}
            >
              <Link to="/our-team">Our Team</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/what-we-do" ? "active" : ""
              }`}
            >
              <Link to="/what-we-do">What we do</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/programs" ? "active" : ""
              }`}
            >
              <Link to="/programs">Programs</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/mission" ? "active" : ""
              }`}
            >
              <Link to="/mission">Our Mission</Link>
            </li>
          </ul>
          <FaBars
            className="navbar_icon"
            onClick={() => setLinksIsOpen(true)}
          />
          <ul
            className={`toggle_navbar ${linksIsOpen ? "active" : ""}`}
            onClick={() => {
              if (linksIsOpen) {
                setLinksIsOpen(false);
              }
            }}
          >
            <div className="close">
              <AiOutlineClose
                className="navbar_icon"
                onClick={() => {
                  setLinksIsOpen(false);
                }}
              />
            </div>
            <li
              className={` mobile ${location.pathname === "/" ? "active" : ""}`}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/aboutus" ? "active" : ""
              }`}
            >
              <Link to="/aboutus">About</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/programs" ? "active" : ""
              }`}
            >
              <Link to="/programs">Programs</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/mission" ? "active" : ""
              }`}
            >
              <Link to="/mission">Our Mission</Link>
            </li>

            <li
              className={`mobile ${
                location.pathname === "/our-team" ? "active" : ""
              }`}
            >
              <Link to="/our-team">Our Team</Link>
            </li>
            <li
              className={`mobile ${
                location.pathname === "/what-we-do" ? "active" : ""
              }`}
            >
              <Link to="/what-we-do">What We Do</Link>
            </li>
            <li
              className={`${location.pathname === "/gallery" ? "active" : ""}`}
            >
              <Link to="/gallery">Gallery</Link>
            </li>
            <li
              className={`${location.pathname === "/contact" ? "active" : ""}`}
            >
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
