import React from "react";
import Hero from "../../components/Challenges/Hero/Hero";
import Main from "../../components/Challenges/Main/Main";

function Challenges() {
  return (
    <div>
      <Hero />
      <Main />
    </div>
  );
}

export default Challenges;
