import React, { useRef, useState } from "react";
import { motion } from "framer-motion";

import "./Frame.css";
import image from "../../../images/frame.png";

function Frame() {
  const [isOpen, setIsOpen] = useState(false);
  const contentEl = useRef(null);
  return (
    <div className="about_frame">
      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
        className="about_frame_container"
      >
        <img src={image} alt="frame" className="image" />
        <div className="content">
          <p className="title">About Dirghayu Nepal</p>
          <p className="desc">
            Dirghayu Nepal, meaning "Longevity Nepal" in English, is more than
            just an organization — we are a family committed to fostering
            positive change and longevity in our communities. Registered with
            the Social Welfare Council (Registration No: 41125) in Kathmandu and
            the District Administration Office, Kaski (Registered No: 3274), our
            organization is dedicated to making a lasting impact on the lives of
            individuals across Nepal.
          </p>
          <p
            ref={contentEl}
            className="desc readmore"
            style={
              isOpen
                ? { height: contentEl.current.scrollHeight }
                : { height: "0px" }
            }
          >
            <div>
              At Dirghayu Nepal, we tackle a wide range of issues to create
              holistic and sustainable change. Our focus areas include health,
              sanitation, environment, child rights, Trainings, skill
              development, area development, Technical Entrepreneurship and
              capacity building. By addressing these critical issues, we aim to
              empower individuals and communities to lead healthier, more
              prosperous lives.
            </div>

            <div style={{ marginTop: "20px" }}>
              We firmly believe in the power of collective action. By working
              together and harnessing the strengths of our community, we can
              overcome challenges and create meaningful impact. We strive to
              build partnerships and collaborations with various international
              agencies, including Erasmus+, Korean organizations, INGOs,
              Governmental, and Non-governmental agencies, to amplify our
              efforts and reach more people in need.
            </div>
          </p>
          <p
            className="button"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? "Read less" : "Read more"}
          </p>
        </div>
      </motion.div>
    </div>
  );
}

export default Frame;
