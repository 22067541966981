import React, { useState } from "react";
import "./Dropdown.css";
import { BsChevronDown } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";

function Dropdown({ options, icon, userEntry, setUserEntry }) {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState(options ? options[0] : "");

  return (
    <div className="dropdown">
      <div
        className={`dropdown_select ${isOpen ? "dropdown_select-clicked" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span>{selectedValue}</span>
        <div
          className={`dropdown_caret ${isOpen ? "dropdown_caret-rotate" : ""}`}
        >
          {icon === "plus" ? <AiOutlinePlus /> : <BsChevronDown />}
        </div>
      </div>
      <ul className={`dropdown_menu ${isOpen ? "dropdown_menu-open" : ""}`}>
        {options?.map((option, i) => {
          return (
            <li
              onClick={() => {
                setSelectedValue(option);
                setUserEntry({ ...userEntry, contact_method: option });
                setIsOpen(!isOpen);
              }}
              key={i}
              className={option === selectedValue ? "dropdown_active" : ""}
            >
              {option}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Dropdown;
