import React from "react";
import "./TopContainer.css";
import { BsShare } from "react-icons/bs";
import { BUCKET_URL } from "../../Home/Partner/Partner";

function TopContainer({ partner }) {
  return (
    <div className="profile_top_heading">
      <div className="profile_top_heading_container">
        <div className="profile_top_logo">
          <div>
            <img
              src={`${BUCKET_URL}/programs/${partner?.images.at(0)}`}
              alt="logo"
            />
          </div>
        </div>
        <div className="profile_top_content">
          <div className="profile_top_content_upper">
            <div>
              <h2>{partner?.title}</h2>
            </div>
            <button>
              <BsShare />
              Share
            </button>
          </div>
          <div className="profile_top_content_lower">
            <h5>{partner?.service_title}</h5>
            <p>{partner?.location}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopContainer;
