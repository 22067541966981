import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Profile.css";

import TopContainer from "../../components/Profile/TopContainer/TopContainer";
import SideContainer from "../../components/Profile/SideContainer/SideContainer";
import CenterContainer from "../../components/Profile/CentreContainer/CenterContainer";
import { PROGRAMS } from "../../components/Home/Partner/Partner";

function Profile() {
  const params = useParams();

  const [similarProfile, setSimilarProfile] = useState(null);

  const [partnerProfile, setPartnerProfile] = useState(null);

  useEffect(() => {
    let otherPartners = [...PROGRAMS];
    let activePartner = PROGRAMS.find((el) => el._id === params.id);
    otherPartners.splice(PROGRAMS.indexOf(activePartner), 1);
    setSimilarProfile(otherPartners.slice(0, 3));
    setPartnerProfile(activePartner);
    // eslint-disable-next-line
  }, [params.id]);

  return (
    <>
      {partnerProfile ? (
        <div className="profile">
          <div className="profile_top">
            <div></div>
          </div>
          <div className="position_relative_profile">
            <div className="position_absolute_profile">
              <TopContainer partner={partnerProfile} />
            </div>
            <div className="profile_bottom">
              <SideContainer
                partner={partnerProfile}
                similarProfiles={similarProfile}
              />
              <CenterContainer partner={partnerProfile} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Profile;
