import React from "react";
import "./About.css";
import Newsletter from "../../components/Newsletter/Newsletter";
import Hero from "../../components/Aboutus/Hero/Hero";
// import FAQ from "../../components/Aboutus/FAQ/FAQ";
import Frame from "../../components/Aboutus/Frame/Frame";

function About() {
  return (
    <div>
      <Hero />
      <Frame />
      {/* <Team /> */}
      <Newsletter />
      {/* <FAQ /> */}
    </div>
  );
}

export default About;
