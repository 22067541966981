import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import "./Search.css";
import { BsSearch } from "react-icons/bs";
import Card from "../../Card/Card";
import { Link } from "react-router-dom";
import { PROGRAMS } from "../../Home/Partner/Partner";

function Search({ admin }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPartners, setSearchPartners] = useState([]);
  // To fix the problem of blank page when reloading
  useEffect(() => {
    setSearchPartners(PROGRAMS);
  }, []);
  const [page, setPage] = useState(1);
  const itemPerPage = 6;

  useEffect(() => {
    let filtered_partners = PROGRAMS;
    let search_term = RegExp(searchTerm, "i");

    if (searchTerm) {
      filtered_partners = PROGRAMS.filter(
        (partner) =>
          partner?.tags?.includes(searchTerm) ||
          partner?.title?.match(search_term)
      );
    }

    setSearchPartners(filtered_partners);
    // eslint-disable-next-line
  }, [searchTerm]);

  const handleViewMore = () => {
    if (page * itemPerPage >= searchPartners?.length) {
      setPage(1);
    } else {
      setPage(page + 1);
    }
  };

  return (
    <div className="onboard">
      <div className="onboard_container">
        <motion.div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="onboard_partner_search"
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {admin ? (
              <Link to={"/admin/add-partner"} className="addPartner">
                Add Partner
              </Link>
            ) : null}
          </div>

          <form
            className="search"
            onSubmit={(e) => {
              e.preventDefault();
              setSearchTerm(e.target.searchTerm.value);
            }}
          >
            <div className="search_content">
              <BsSearch className="search_icon" />
              <input
                type="text"
                name="searchTerm"
                className="searchTerm"
                placeholder="Search"
              />
            </div>
            <button type="submit" className="primary-btn search_button">
              Search
            </button>
          </form>
        </motion.div>

        <div className="partner_cards marketplace">
          {searchPartners?.slice(0, page * itemPerPage).map((partner, i) => {
            return <Card partner={partner} key={i} admin={admin} />;
          })}
        </div>

        {PROGRAMS.length !== 0 && (
          <motion.div
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="partner_button"
          >
            {page * itemPerPage < searchPartners?.length && (
              <button className="secondary-btn" onClick={handleViewMore}>
                View More
              </button>
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default Search;
