import React from "react";
import "./TeamCard.css";

import { FaFacebook } from "react-icons/fa";
import { motion } from "framer-motion";

function TeamCard({ member }) {
  return (
    <>
      <motion.div
        whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
        viewport={{ once: true }}
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5, type: "tween" }}
        className="team_card"
      >
        <div className="teamcard_image">
          <img src={`team/${member.image}`} alt="member" />
        </div>
        <div className="team_description">
          <div className="team_name_container">
            <p className="memb_name">{member?.name}</p>
          </div>
          <p className="memb_position">{member?.position}</p>
          <p className="memb_desc">{member?.description}</p>
          <div className="memb_social_links">
            <a href={member?.twitter_link} target="_black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 1668.56 1221.19"
                version="1.1"
                viewBox="0 0 1668.56 1221.19"
                xmlSpace="preserve"
              >
                <circle cx="834.28" cy="598.545" r="700" fill="#707174" />
                <g transform="translate(52.39 -25.059)">
                  <path
                    d="M283.94 167.31l386.39 516.64L281.5 1104h87.51l340.42-367.76L984.48 1104h297.8L874.15 558.3l361.92-390.99h-87.51l-313.51 338.7-253.31-338.7h-297.8zm128.69 64.46h136.81l604.13 807.76h-136.81L412.63 231.77z"
                    fill="white"
                    strokeWidth={4}
                  ></path>
                </g>
              </svg>
            </a>
            <a href={member?.facebook_link} target="_black">
              <FaFacebook />
            </a>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default TeamCard;
