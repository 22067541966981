import React, { useState } from "react";
import "./Contact.css";
import Email from "../../components/Contact/Email/Email";
import Detail from "../../components/Contact/Details/Detail";
import Alert from "../../components/Alert/Alert";

function Contact() {
  const [alert, setAlert] = useState({
    isOpen: false,
    message: "message",
    type: "success",
  });
  return (
    <div className="contact">
      <Alert alert={alert} />
      <div className="contact_container">
        <Email alert={alert} setAlert={setAlert} />
        <Detail />
      </div>
    </div>
  );
}

export default Contact;
