import React, { useRef, useState } from 'react';
import { FaCalendarDay } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";


function DropdownFaq({ item }) {
    const [isOpen, setIsOpen] = useState(false);
    const contentEl = useRef(null);

    return (
        <div className="item">
            <div className="icon">
                <FaCalendarDay />
            </div>

            <div className="text">
                <p onClick={() => { setIsOpen(!isOpen) }} className="question">{item.question} </p>
                <p ref={contentEl} className="answer" style={isOpen ? { height: contentEl.current.scrollHeight } : { height: "0px" }}>
                    {item.answer}
                </p>
                <AiFillCaretDown className={`dropdown_icon ${isOpen ? 'rotate' : ''}`} onClick={() => { setIsOpen(!isOpen) }} />
            </div>
        </div>
    )
}

export default DropdownFaq
