import React from "react";
import Hero from "../../components/CompanyOnBoard/Hero/Hero";
import Search from "../../components/CompanyOnBoard/Search/Search";

function CompanyOnBoard({ admin }) {
  return (
    <div className="company_onboard_marketplace">
      <Hero />
      <Search admin={admin} />
    </div>
  );
}

export default CompanyOnBoard;
