import React, { useState } from "react";
import { motion } from "framer-motion";

import "./Team.css";
import TeamCard from "../TeamCard/TeamCard";

const TEAMS = [
  {
    id: 1,
    name: "Sundar Adhikari",
    position: "President",
    facebook_link: "https://facebook.com",
    image: "/Team1.jpg",
  },
  {
    id: 2,
    name: "Keshab Bhandari",
    position: "Vice-President",
    facebook_link: "https://facebook.com",
    image: "/Team2.jpg",
  },
  {
    id: 3,
    name: "Anil Lamichhane",
    position: "Secretary",
    facebook_link: "https://facebook.com",
    image: "/Team3.jpg",
  },
  {
    id: 4,
    name: "Aruna Neupane",
    position: "Treasurer",
    facebook_link: "https://facebook.com",
    image: "/Team4.jpg",
  },
  {
    id: 5,
    name: "Pradeep Pahari",
    position: "Member",
    facebook_link: "https://facebook.com",
    image: "/Member1.jpg",
  },
  {
    id: 6,
    name: "Roshani Gurung",
    position: "Member",
    facebook_link: "https://facebook.com",
    image: "/Member2.jpg",
  },
  {
    id: 7,
    name: "Shushil Tripathee",
    position: "Member",
    facebook_link: "https://facebook.com",
    image: "/Member5.jpg",
  },
  {
    id: 8,
    name: "Narmada Rana",
    position: "Member",
    facebook_link: "https://facebook.com",
    image: "/Member4.jpg",
  },
  {
    id: 9,
    name: "Govinda Subedi",
    position: "Member",
    facebook_link: "https://facebook.com",
    image: "/Member3.jpg",
  },
  {
    id: 8,
    name: "Buddhi Raj Joshi",
    position: "Advisor",
    facebook_link: "https://facebook.com",
    image: "/Advisor1.jpg",
  },
  {
    id: 9,
    name: "Dr. David Shrestha",
    position: "Advisor",
    facebook_link: "https://facebook.com",
    image: "/Advisor2.jpg",
  },
];

function Team({ admin }) {
  const [page, setPage] = useState(1);
  const itemPerPage = 6;

  const handleLoadMore = () => {
    if (page * itemPerPage >= TEAMS?.length) {
      setPage(1);
    } else {
      setPage(page + 1);
    }
  };

  return (
    <div className="team">
      <div className="team_container">
        <div className="team_headline">
          <motion.h3
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            Our Core Team
          </motion.h3>
          <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            Our team comprises passionate social workers hailing from diverse
            backgrounds, including health, development, environment, finance,
            management, academics, entrepreneurship and journalism. This
            diversity enriches our approach to addressing various social issues
            and enables us to bring a comprehensive perspective to our work.
          </motion.p>
        </div>
        <div className="team_cards">
          {TEAMS?.slice(0, page * itemPerPage).map((member, i) => {
            return (
              <div key={i}>
                <TeamCard admin={admin} member={member} />
              </div>
            );
          })}
        </div>
      </div>
      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.7 }}
        viewport={{ once: true }}
        className="team_button"
      >
        <button className="primary-btn" onClick={handleLoadMore}>
          {TEAMS?.length <= page * itemPerPage ? "View less" : "View more"}
        </button>
      </motion.div>
    </div>
  );
}

export default Team;
