import "./Card.css";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { BUCKET_URL } from "../Home/Partner/Partner";

function Card({ partner }) {
  return (
    <Link to={`/profile/${partner._id}`}>
      <motion.div
        whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.6, type: "tween" }}
        viewport={{ once: true }}
        className="card"
      >
        <div className="img_container">
          <img src={`${BUCKET_URL}/programs/${partner.images.at(0)}`} alt="partner" />
        </div>
        <div className="card_content">
          <div
            className="card_name"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: "10px",
              position: "relative",
            }}
          >
            <p className="card_title">{partner?.title}</p>
          </div>
          <p className="card_desc">{partner?.short_des}</p>
          <div className="card_link">
            <Link to={`/profile/${partner._id}`}>
              Learn More <FaArrowRight />
            </Link>
          </div>
        </div>
      </motion.div>
    </Link>
  );
}

export default Card;
