import React from "react";
import "./Main.css";
import { motion } from "framer-motion";

function Main() {
  return (
    <div className="challenges_main">
      {/* <svg
        className="leftsvg"
        xmlns="http://www.w3.org/2000/svg"
        width="476"
        height="493"
        viewBox="0 0 476 493"
        fill="none"
      >
        <path
          d="M247.5 39.993C325.434 64.5198 408.343 89.9086 446.504 149.015C486.381 210.778 485.077 286.655 447.117 349.171C411.403 407.989 332.813 437.167 256.513 461.913C182.794 485.822 104.31 504.225 28.4532 484.955C-51.7304 464.586 -122.68 419.632 -153.758 357.69C-183.996 297.424 -156.765 232.551 -128.761 171.613C-99.8341 108.668 -71.5419 37.4185 5.86649 10.3402C83.3652 -16.7697 168.765 15.2141 247.5 39.993Z"
          fill="#02320A"
          fillOpacity="0.03"
        />
      </svg>
      <svg
        className="rightsvg"
        xmlns="http://www.w3.org/2000/svg"
        width="387"
        height="572"
        viewBox="0 0 387 572"
        fill="none"
      >
        <path
          d="M459.261 17.902C354.761 -38.098 -109.239 35.902 23.7615 259.402C156.761 482.902 23.761 507.902 223.261 561.902C422.762 615.902 678.761 428.902 684.761 309.902C690.761 190.902 563.762 73.902 459.261 17.902Z"
          fill="#02320A"
          fillOpacity="0.03"
        />
      </svg> */}
      <div className="challenges_main_container">
        <div className="upper">
          {/* <div className="heading">
            <motion.p
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="title"
            >
              Challenges
            </motion.p>
            <motion.p
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.7 }}
              viewport={{ once: true }}
              className="desc"
            >
              Embracing Challenges, Fueling Growth: Gurkha Connection
            </motion.p>
          </div> */}
          <div className="content">
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title"> Literacy & Education</p>
              <p className="desc">
                We assist in educating communities by providing knowledge about
                the importance of education and sending kids to school.
              </p>
            </motion.div>
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title">Food & shelter</p>
              <p className="desc">
                We help in empowering people in need of food & shelter. We know
                it is the most basic need of human beings for their survival.
              </p>
            </motion.div>
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title"> Health & Sanitation</p>
              <p className="desc">
                We focus on mental health, particularly in relation to Post
                Traumatic Stress Disorder (PTSD), and provide health camps
                according to the need in various locations.
              </p>
            </motion.div>
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title">Capacity Building</p>
              <p className="desc">
                We are here to enhance and improve the quality of teachers
                through various capacity-building initiatives of the available
                local resources.
              </p>
            </motion.div>
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title">Awareness Program</p>
              <p className="desc">
                We organize awareness programs in society to aware the community
                about various illnesses, clinical conditions, and pandemics.
              </p>
            </motion.div>
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title">Contribution Programs</p>
              <p className="desc">
                We have different volunteering positions to assist and support
                the community for better development and knowledge.
              </p>
            </motion.div>
          </div>
        </div>
        <div className="lower">
          <div className="heading">
            <motion.p
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="title"
            >
              Our Vision
            </motion.p>
            {/* <motion.p
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.7 }}
              viewport={{ once: true }}
              className="desc"
            >
              Empowering Success: Gurkha Connection wields a diverse arsenal of
              digital tools
            </motion.p> */}
          </div>
          {/* <div className="content">
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title">Digital Transformation</p>
              <p className="desc">
                Embracing digital technologies is crucial for Nepal to overcome
                its technological infrastructure challenges. By investing in
                improving internet connectivity, developing user-friendly online
                platforms, and leveraging digital marketing techniques, Nepal
                can effectively reach and engage with a global audience. This
                includes optimizing websites, utilizing social media platforms,
                and employing search engine optimization (SEO) strategies to
                enhance visibility and attract international tourists.
              </p>
            </motion.div>
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title">Strategic Partnerships</p>
              <p className="desc">
                Collaborating with international travel agencies, tour
                operators, and influential figures in the travel industry can
                significantly boost Nepal's visibility and market presence.
                Establishing strategic partnerships allows for joint marketing
                initiatives, increased access to distribution channels, and the
                development of specialized tour packages tailored to the
                preferences of target markets.
              </p>
            </motion.div>
          </div> */}
          {/* <motion.div
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="content"
          >
            <div className="text">
              <p className="title">Capacity Building and Training</p>
              <p className="desc">
                Investing in training programs and capacity building initiatives
                is essential to address the shortage of skilled human resources
                in the tourism sector. By providing training in digital
                marketing, international business development, customer service,
                and cultural sensitivity, Nepal can nurture a pool of competent
                professionals capable of promoting the country and its products
                effectively.
              </p>
            </div>
            <motion.div
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text"
            >
              <p className="title">Tailored Marketing Campaigns</p>
              <p className="desc">
                Developing targeted marketing campaigns that highlight Nepal's
                unique selling points and appeal to specific segments of the
                international market is crucial. By conducting market research,
                identifying key target markets, and tailoring promotional
                activities to cater to their preferences, Nepal can maximize its
                impact and generate interest among potential travelers.
              </p>
            </motion.div>
          </motion.div> */}
        </div>
        <motion.div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="last"
        >
          <p>
            At Dirghayu Nepal, our vision is clear and ambitious: to establish
            an empowered, educated community that is socially and financially
            independent, equipped with access to comprehensive healthcare
            facilities. We strive to foster a society where every individual has
            the opportunity to thrive, by robust capacity-building iniatiatives
            that enable personal and professional growth regardless of their
            background or circumstances. Central to our vision is our commitment
            to environmental conservation and sustainability. We recognize the
            importance of preserving our natural resources for future
            generations and ensuring their optimal utilization. By caring for
            our environment, we aim to create a world where both people and
            nature can flourish harmoniously.
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default Main;
