import React from "react";
import "./Footer.css";
import {
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import MotionWrap from "../../wrapper/motionWrapper";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div id="footer">
      <div className="footer_container">
        <div className="footer_content">
          <div className="footer_about">
            <p className="footer_title">Dirghayu Nepal</p>
            <div className="footer_desc">
              <p>Dirghayu Nepal is here to serve the welfare of children,</p>
              <p> the elderly, and those who are disabled or vulnerable.</p>
            </div>
            <div className="social_links">
              <a href="https://www.instagram.com" target="_black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="21"
                    height="21"
                    rx="6"
                    fill="#97A1AF"
                  />
                  <rect
                    x="1.5"
                    y="1.5"
                    width="21"
                    height="21"
                    rx="6"
                    fill="#97A1AF"
                  />
                  <rect
                    x="1.5"
                    y="1.5"
                    width="21"
                    height="21"
                    rx="6"
                    fill="#97A1AF"
                  />
                  <path
                    d="M17.25 7.875C17.25 8.49632 16.7463 9 16.125 9C15.5037 9 15 8.49632 15 7.875C15 7.25368 15.5037 6.75 16.125 6.75C16.7463 6.75 17.25 7.25368 17.25 7.875Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75ZM12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5 11.7C4.5 9.17976 4.5 7.91965 4.99047 6.95704C5.4219 6.11031 6.11031 5.4219 6.95704 4.99047C7.91965 4.5 9.17976 4.5 11.7 4.5H12.3C14.8202 4.5 16.0804 4.5 17.043 4.99047C17.8897 5.4219 18.5781 6.11031 19.0095 6.95704C19.5 7.91965 19.5 9.17976 19.5 11.7V12.3C19.5 14.8202 19.5 16.0804 19.0095 17.043C18.5781 17.8897 17.8897 18.5781 17.043 19.0095C16.0804 19.5 14.8202 19.5 12.3 19.5H11.7C9.17976 19.5 7.91965 19.5 6.95704 19.0095C6.11031 18.5781 5.4219 17.8897 4.99047 17.043C4.5 16.0804 4.5 14.8202 4.5 12.3V11.7ZM11.7 6H12.3C13.5849 6 14.4583 6.00117 15.1334 6.05633C15.7911 6.11006 16.1274 6.20745 16.362 6.32698C16.9265 6.6146 17.3854 7.07354 17.673 7.63803C17.7926 7.87263 17.8899 8.20893 17.9437 8.86656C17.9988 9.54169 18 10.4151 18 11.7V12.3C18 13.5849 17.9988 14.4583 17.9437 15.1334C17.8899 15.7911 17.7926 16.1274 17.673 16.362C17.3854 16.9265 16.9265 17.3854 16.362 17.673C16.1274 17.7926 15.7911 17.8899 15.1334 17.9437C14.4583 17.9988 13.5849 18 12.3 18H11.7C10.4151 18 9.54169 17.9988 8.86656 17.9437C8.20893 17.8899 7.87263 17.7926 7.63803 17.673C7.07354 17.3854 6.6146 16.9265 6.32698 16.362C6.20745 16.1274 6.11006 15.7911 6.05633 15.1334C6.00117 14.4583 6 13.5849 6 12.3V11.7C6 10.4151 6.00117 9.54169 6.05633 8.86656C6.11006 8.20893 6.20745 7.87263 6.32698 7.63803C6.6146 7.07354 7.07354 6.6146 7.63803 6.32698C7.87263 6.20745 8.20893 6.11006 8.86656 6.05633C9.54169 6.00117 10.4151 6 11.7 6Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a href="https://www.facebook.com/" target="_black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle cx="12" cy="12" r="10.5" fill="#97A1AF" />
                  <path
                    d="M15.9103 15.2112L16.3767 12.2476H13.4589V10.3252C13.4589 9.51428 13.8657 8.7233 15.1726 8.7233H16.5V6.20024C16.5 6.20024 15.2959 6 14.1452 6C11.7411 6 10.1712 7.4197 10.1712 9.98883V12.2476H7.5V15.2112H10.1712V22.3759C10.7075 22.458 11.2562 22.5 11.8151 22.5C12.374 22.5 12.9226 22.458 13.4589 22.3759V15.2112H15.9103Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a href="https://www.twitter.com" target="_black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  enableBackground="new 0 0 1668.56 1221.19"
                  version="1.1"
                  viewBox="0 0 1668.56 1221.19"
                  xmlSpace="preserve"
                >
                  <circle cx="834.28" cy="598.545" r="700" fill="#97A1AF" />
                  <g transform="translate(52.39 -25.059)">
                    <path
                      d="M283.94 167.31l386.39 516.64L281.5 1104h87.51l340.42-367.76L984.48 1104h297.8L874.15 558.3l361.92-390.99h-87.51l-313.51 338.7-253.31-338.7h-297.8zm128.69 64.46h136.81l604.13 807.76h-136.81L412.63 231.77z"
                      fill="white"
                      strokeWidth={4}
                    ></path>
                  </g>
                </svg>
              </a>
              <a href="https://www.linkedin.com" target="_black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="21"
                    height="21"
                    rx="10.5"
                    fill="#97A1AF"
                  />
                  <path
                    d="M9.46392 7.26911C9.46392 7.97002 8.85639 8.53822 8.10696 8.53822C7.35753 8.53822 6.75 7.97002 6.75 7.26911C6.75 6.5682 7.35753 6 8.10696 6C8.85639 6 9.46392 6.5682 9.46392 7.26911Z"
                    fill="white"
                  />
                  <path
                    d="M6.93557 9.47107H9.25515V16.5H6.93557V9.47107Z"
                    fill="white"
                  />
                  <path
                    d="M12.9897 9.47107H10.6701V16.5H12.9897C12.9897 16.5 12.9897 14.2872 12.9897 12.9036C12.9897 12.0732 13.2732 11.2392 14.4046 11.2392C15.6833 11.2392 15.6756 12.3259 15.6696 13.1678C15.6618 14.2683 15.6804 15.3914 15.6804 16.5H18V12.7903C17.9804 10.4215 17.3631 9.33006 15.3325 9.33006C14.1265 9.33006 13.379 9.87754 12.9897 10.3729V9.47107Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="footer_company">
            <p className="footer_content_title">Company</p>
            <Link to="/aboutus">About</Link>
            {/* <Link to="/">Careers</Link> */}
            {/* <Link to="/whyus">Why Us</Link> */}
            {/* <Link to="/services/review">Review</Link> */}
            <Link to="/contact">Contact</Link>
          </div>
          <div className="footer_support">
            <p className="footer_content_title">Support</p>
            {/* <Link to="/FAQs">FAQs</Link> */}
            <Link to="/what-we-do">What we do</Link>
          </div>
          <div className="footer_contact">
            <p className="footer_content_title">Contact us</p>
            <a href="mailto:dirghayunepal75@gmail.com">
              <HiOutlineMail /> dirghayunepal75@gmail.com
            </a>
            <a href="tel:+977-61581901">
              <HiOutlinePhone />
              +977-61581901
            </a>
            <a
              href="https://www.google.com/maps/search/32+Keatsway,London/@51.5285257,-0.2667488,11z/data=!3m1!4b1?entry=ttu"
              target="_black"
            >
              <HiOutlineLocationMarker /> Srijana Chowk, Pokhara -8,Nepal
            </a>
          </div>
        </div>
        <span className="stLine"></span>
        <div className="footer_info">
          <div className="copyright">
            <p>&#169; 2024 Dirghayu Nepal - All rights reserved</p>
          </div>
          <div className="legal">
            {/* <Link to="/">Terms of Service</Link>
            <Link to="/">Privacy Policy</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MotionWrap(Footer, "footer");
