import React from "react";
import "./Service.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const content = [
  {
    title: "Literacy & Education",
    desc: " We assist in educating communities by providing knowledge about the importance of education and sending kids to school.",
  },
  {
    title: "Food & shelter",
    desc: " We help in empowering people in need of food & shelter. We know it is the most basic need of human beings for their survival.",
  },
  {
    title: "Health & Sanitation",
    desc: " We focus on mental health, particularly in relation to Post  Traumatic Stress Disorder (PTSD), and provide health camps according to the need in various locations.",
  },
  {
    title: "Capacity Building",
    desc: "We are here to enhance and improve the quality of teachers through various capacity-building initiatives of the available local resources.",
  },
];

function Service() {
  return (
    <div className="home_service">
      <div className="home_service_container">
        <div className="heading">
          <motion.h1
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            Our Mission and Vision
          </motion.h1>
        </div>
        <div className="content">
          {content?.map((item, i) => {
            return (
              <motion.div
                whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                className="item"
                key={i}
              >
                <p className="title">{item.title}</p>
                <p className="desc">{item.desc}</p>
                <Link to="/mission">
                  Read More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.5 13.75L13.3333 7.91663"
                      stroke="#2b308c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 7.91663H13.3333V13.75"
                      stroke="#2b308c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Service;
