import React from "react";
import "./Service.css";
import { motion } from "framer-motion";

const content = [
  {
    title: "Health",
    desc: "Dirghayu Nepal supports both rural and urban locations to have simple access to health care. Dirghayu Nepal raises awareness on “Small Family Happy Family”, various illnesses of aged people, the importance of women's health, clinical conditions, and pandemics. Additionally, we focus on mental health,  particularly in relation to  Post Traumatic Stress Disorder(PTSD) and we provide health Camps according to Need in Various Locations.",
  },
  {
    title: "Education",
    desc: "We are here to educate children who live in poverty and have stopped attending school. Additionally, Dirghayu Nepal assists in improving school facilities. We also assist in educating communities about the importance of sending kids to school. Furthermore, we assist in building the capacity of local resource individuals. We promote education for women and emphasize their significance in the current and future of society.",
  },
  {
    title: "Environment ",
    desc: "To establish community forests in dry soils and areas vulnerable to landslides, our team is always prepared. We are also here to educate local people about environmental issues, their effects, and prospects for conservation of the environment. We impart fresh plantation methods that promote environmental preservation. We also support the preservation of habitats for endangered animals and plants.",
  },
  {
    title: "Capacity Building:",
    desc: "Dirghayu Nepal Involves to improve the productivity of all organizational personnel through various seminars and training.  We are right here enhancing the quality of teachers through various capacity-building initiatives. We wanted to support parents who are struggling to support their families. We also wish to expand and improve the capability of the available local resources. Dirghayu Nepal is constantly available to help many local populations improve their technical skills for daily living, which in turn allows them to be economically stable. Moreover, we wish to offer training to groups, communities, and individuals that ensures the best possible upgraded use of the resources at hand.",
  },
];

function Service() {
  return (
    <div className="service_page">
      <div className="service_page_container">
        {/* <div className="heading">
          <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            At Gurkha Connection, we're more than just a platform - we're your
            ally in the pursuit of global success. Join us today and unlock a
            world of opportunities that will elevate your business to
            extraordinary heights. Your journey to prosperity starts here.
          </motion.p>
        </div> */}
        <div className="content">
          {content?.map((item, i) => {
            return (
              <motion.div
                whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                transition={{ duration: 0.9 }}
                viewport={{ once: true }}
                className="item"
                key={i}
              >
                <p className="title">{item.title}</p>
                <p className="desc">{item.desc}</p>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Service;
