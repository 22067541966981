import React from "react";
import "./Hero.css";

function Hero() {
  return (
    <div className="faq_hero common_faq">
      <div className="faq_hero_container">
        <div className="top">
          <p className="title">Why Us?</p>
          <p className="heading">
            Elevate Your Brand with Our Data-Driven Digital Marketing Solutions
          </p>
          <p className="desc">
            Empowering Brands to Shine: Gurkha Connection, Your Growth Partner
            in Digital Marketing
          </p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
