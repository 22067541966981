import React from "react";

import "./Hero.css";
import "react-multi-carousel/lib/styles.css";

import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Hero() {
  const sliderData = [
    {
      img: "/hero1.jpg",
    },
    {
      img: "/hero2.jpg",
    },
    {
      img: "/hero3.jpg",
    },
  ];
  return (
    <div className="carousel-class">
      <Carousel
        customRightArrow={<></>}
        customLeftArrow={<></>}
        swipeable={true}
        infinite={true}
        autoPlay
        autoPlaySpeed={4000}
        customTransition="all .7s"
        itemClass="item_class"
        containerClass="container_class"
        showDots
        partialVisible={false}
        responsive={responsive}
      >
        {sliderData.map((item, i) => (
          <div
            key={i}
            style={{
              height: "575px",
              width: "90%",
              alignSelf: "center",
            }}
          >
            <img
              src={item.img}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "575px",
              }}
              alt=""
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Hero;
