import React from "react";
import { motion } from "framer-motion";

import { FaArrowRight } from "react-icons/fa";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";

import "./Detail.css";
import { Link } from "react-router-dom";

function Detail() {
  return (
    <div className="contact_details">
      <div className="contact_details_upper">
        <motion.h1
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          Get in touch
        </motion.h1>
        <motion.p
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="contact_details_desc"
        >
          Thank you for your interest in Dirghayu Nepal. We&#39;re here to
          assist you with any inquiries you may have. Please feel free to reach
          out to us via the following contact details:
        </motion.p>
        <motion.div
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="card_link"
          style={{
            color: "#0057FF",
            marginTop: "8px",
            display: "flex",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <Link to="/aboutus">Learn More</Link> <FaArrowRight />
        </motion.div>
      </div>
      <div className="contact_details_lower">
        <motion.h1
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          Address
        </motion.h1>
        <motion.p
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="contact_details_desc"
        >
          Srijana Chowk, Pokhara -8,Nepal
        </motion.p>
        <motion.a
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          href="mailto:dirghayunepal75@gmail.com"
          className="contact_details_icon"
        >
          <AiOutlineMail />
          Send an email
        </motion.a>
        <motion.a
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          href="tel:+977-9856053175"
          className="contact_details_icon"
        >
          <BsTelephone />
          Make a phone call
        </motion.a>
      </div>
      <div className="contact_details_social_links">
        <motion.h1
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          Social media
        </motion.h1>
        <motion.div
          whileInView={{ x: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <a href="https://www.instagram.com" target="_black">
            <FaInstagram />
          </a>
          <a href="https://www.facebook.com/" target="_black">
            <FaFacebook />
          </a>
          <a href="https://www.twitter.com" target="_black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              enableBackground="new 0 0 1668.56 1221.19"
              version="1.1"
              viewBox="0 0 1668.56 1221.19"
              xmlSpace="preserve"
            >
              <circle cx="834.28" cy="598.545" r="700" fill="white" />
              <g transform="translate(52.39 -25.059)">
                <path
                  d="M283.94 167.31l386.39 516.64L281.5 1104h87.51l340.42-367.76L984.48 1104h297.8L874.15 558.3l361.92-390.99h-87.51l-313.51 338.7-253.31-338.7h-297.8zm128.69 64.46h136.81l604.13 807.76h-136.81L412.63 231.77z"
                  fill="black"
                  stroke="black"
                  stroke-width="4"
                ></path>
              </g>
            </svg>
          </a>
          <a href="https://www.linkedin.com" target="_black">
            <FaLinkedin />
          </a>
        </motion.div>
      </div>
    </div>
  );
}

export default Detail;
