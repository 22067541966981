import React from "react";
import Hero from "../../components/Whyus/Hero/Hero";
import Main from "../../components/Whyus/Main/Main";

function Whyus() {
  return (
    <div>
      <Hero />
      <Main />
    </div>
  );
}

export default Whyus;
