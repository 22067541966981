import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import CompanyOnBoard from "./pages/CompanyOnBoard/CompanyOnBoard";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Profile from "./pages/Profile/Profile";
import { createContext, useEffect, useState } from "react";
import axios from "axios";
import FAQ from "./pages/FAQ/FAQ";
import Whyus from "./pages/Whyus/Whyus";
import Challenges from "./pages/Challenges/Challenges";
import ScrollToTop from "./components/ScrollToTop";
import ServicePage from "./pages/Service/ServicePage";
import { Toaster } from "react-hot-toast";
import Team from "./components/Aboutus/Team/Team";
import { PROGRAMS } from "./components/Home/Partner/Partner";
import Gallery from "./components/Gallery/Gallery";

export const GurkhaContext = createContext();

function App() {
  const [partner, setPartner] = useState([]);
  const [teams, setTeams] = useState([]);
  const images = PROGRAMS.flatMap((el) => {
    return el.images;
  });
  console.log(images);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_DOMAIN}/partners`)
      .then((res) => {
        setPartner(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_DOMAIN}/teams`)
      .then((res) => {
        setTeams(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <GurkhaContext.Provider
        value={{
          partner,
          setPartner,
          teams,
        }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/programs" element={<CompanyOnBoard />} />
            <Route exact path="/aboutus" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/profile/:id" element={<Profile />} />
            <Route exact path="/FAQs" element={<FAQ />} />
            <Route exact path="/whyus" element={<Whyus />} />
            <Route exact path="/mission" element={<Challenges />} />
            <Route exact path="/what-we-do" element={<ServicePage />} />
            <Route exact path="/our-team" element={<Team />} />
            <Route exact path="/services/:id" element={<ServicePage />} />
            <Route exact path="/gallery" element={<Gallery />} />
          </Routes>
          <Toaster
            position="bottom-right"
            gutter={12}
            containerStyle={{
              margin: "8px",
            }}
            toastOptions={{
              success: {
                duration: 3000,
              },
              error: {
                duration: 5000,
              },
              style: {
                fontSize: "16px",
                background: "#363636",
                color: "#fff",
              },
            }}
          />
          <Footer />
        </BrowserRouter>
      </GurkhaContext.Provider>
    </>
  );
}

export default App;
