import React from "react";
import { motion } from "framer-motion";

import "./Newsletter.css";
import { Link, useLocation } from "react-router-dom";

function Newsletter() {
  const location = useLocation();
  return (
    <div id="newsletter">
      <div
        className={`newsletter_container ${
          location.pathname === "/aboutus" ||
          location.pathname === "/aboutus/review"
            ? "black"
            : ""
        }`}
      >
        <motion.h2
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="newsletter_title"
        >
          A long-term vision requires an International connection.
        </motion.h2>
        <motion.p
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="newsletter_desc"
        >
          These are essential collaborators who contribute to the success of
          Dirghayu Nepal.
        </motion.p>
        <Link to="/contact">
          <motion.button
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
            className="primary-btn"
          >
            Connect with us
          </motion.button>
        </Link>
      </div>
    </div>
  );
}

export default Newsletter;
