import React from 'react';
import './Alert.css';

function Alert({ alert }) {
    const { isOpen, message, type } = alert;
    return (
        <>
            {isOpen ? <div className={`alert ${type}`}>
                <p className={`alert_message`}>{message}</p>
                <span className='alert_line'></span>
            </div> : <></>}
        </>
    )
}

export default Alert
