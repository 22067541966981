import { BUCKET_URL } from "../Home/Partner/Partner";
import styles from "./Gallery.module.css";
const IMAGES = [
  "cs/1.jpg",
  "cs/2.jpg",
  "cs/3.jpg",
  "cs/4.jpg",
  "cs/5.jpg",
  "cs/6.jpg",
  "rural_schools/1.jpg",
  "rural_schools/2.jpg",
  "rural_schools/3.jpg",
  "rural_schools/4.jpg",
  "rural_schools/5.jpg",
  "rural_schools/6.jpg",
  "rural_schools/7.jpg",
  "rural_schools/8.jpg",
  "rural_schools/9.jpg",
  "rural_schools/10.jpg",
  "rural_schools/11.jpg",
  "rural_schools/12.jpg",
  "rural_schools/13.jpg",
  "rural_schools/14.jpg",
  "rural_schools/15.jpg",
  "rural_schools/16.jpg",
  "masonry/1.jpg",
  "masonry/2.jpg",
  "masonry/3.jpg",
  "masonry/4.jpg",
  "masonry/5.jpg",
  "masonry/6.jpg",
  "masonry/7.jpg",
  "mental_health/1.jpg",
  "mental_health/2.jpg",
  "mental_health/3.jpg",
  "remote_reach/1.jpg",
  "remote_reach/2.jpg",
  "remote_reach/3.jpg",
  "remote_reach/4.jpg",
  "remote_reach/5.jpg",
  "nuwakot/1.JPG",
  "nuwakot/2.JPG",
  "nuwakot/3.JPG",
  "nuwakot/4.JPG",
  "nuwakot/5.JPG",
  "nuwakot/6.JPG",
  "nuwakot/7.JPG",
  "nuwakot/8.JPG",
  "nuwakot/9.JPG",
  "nuwakot/10.JPG",
  "nuwakot/11.JPG",
  "nuwakot/12.JPG",
  "nuwakot/13.JPG",
  "nuwakot/14.JPG",
  "nuwakot/15.JPG",
  "nuwakot/16.JPG",
  "nuwakot/17.JPG",
  "nuwakot/18.JPG",
];
function Gallery() {
  return (
    <div className={styles.container}>
      {IMAGES.slice(1)?.map((gallery, i) => {
        return (
          <div className={styles.img} key={i}>
            <img src={`${BUCKET_URL}/programs/${gallery}`} alt="" />
          </div>
        );
      })}
    </div>
  );
}

export default Gallery;
