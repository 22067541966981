import React from "react";
import "./Main.css";

import { Link } from "react-router-dom";
import DropdownFaq from "./DropdownFaq";

// eslint-disable-next-line
const data = [
  {
    question: "What is Gurkha Connection and what is its mission?",
    answer: "What is Gurkha Connection and what is its mission?",
  },
  {
    question: "Why should I join Gurkha Connection?",
    answer:
      "Joining Gurkha Connection offers several benefits, including harnessing the power of the digital marketplace, expanding your reach globally, streamlining operations and increasing efficiency, and gaining insights with real-time analytics. By joining Gurkha Connection, you become partof a passionate community dedicated to promoting brand Nepal and showcasing its beauty and heritage to the world.",
  },
  {
    question: "What is the mission of Gurkha Connection?",
    answer:
      "Gurkha Connection aims to overcome the challenges faced by Nepal's tourism industry and promote Brand Nepal on a global scale through digital marketing. Our mission is to showcase the beauty and cultural richness of Nepal to the world.",
  },
  {
    question:
      "What are the challenges faced by Nepal's tourism industry, and how does Gurkha Connection address them?",
    answer:
      "Nepal's tourism industry faces challenges such as limited technological infrastructure, insufficient marketing efforts, and a shortage of skilled human resources. Gurkha Connection offers solutions such as digital transformation, strategic partnerships, capacity building, and tailored marketing campaigns. We help improve internet connectivity, establish collaborations, provide training programs, and develop targeted promotional activities to overcome these challenges.",
  },
  {
    question:
      "What are the benefits of joining Gurkha Connection for businesses?",
    answer:
      "By joining Gurkha Connection, businesses can harness the power of the digital marketplace, expand their reach globally, streamline operations, and gain insights through real-time analytics. They can connect with a diverse customer base, focus on core operations, and make informed decisions for business growth.",
  },
  {
    question: "How does Gurkha Connection contribute to promoting brand Nepal?",
    answer:
      "Gurkha Connection is dedicated to showcasing the beauty and heritage of Nepal to the world. By providing a platform for businesses to promote themselves and their products, Gurkha Connection helps in elevating the presence of brand Nepal in the international market and contributing to the country's economic growth.",
  },
  {
    question:
      "How can joining Gurkha Connection benefit my business in the global market?",
    answer:
      "By joining Gurkha Connection, you can harness the power of the digital marketplace and expand your reach globally. Our platform provides an easy onboarding process, efficient promotion assistance, and access to a diverse range of customers actively seeking unique products and services. You can streamline your operations, delegate tasks, and gain insights through real-time analytics to enhance your business's efficiency and make informed decisions.",
  },
  {
    question:
      "What sets Gurkha Connection apart from other platforms in promoting global opportunities?",
    answer:
      "Gurkha Connection's focus on promoting Brand Nepal and its in-depth understanding of the challenges faced by Nepal's tourism industry set us apart. We offer a comprehensive range of tools and strategies specifically tailored to address these challenges and unlock global opportunities. By joining Gurkha Connection, you gain access to our expertise, community support, and a digital marketplace that enables you to elevate your business to new heights in the international market.",
  },
  {
    question:
      "How does Gurkha Connection support businesses in promoting Brand Nepal?",
    answer:
      "Gurkha Connection is dedicated to promoting Brand Nepal and showcasing its beauty and heritage to the world. By joining our platform, you become part of a passionate community working towards the economic growth of Nepal's tourism industry. We provide the necessary tools, resources, and expertise in digital marketing, strategic partnerships, capacity building, and tailored marketing campaigns to help you unlock Nepal's true potential and establish your company as a leading player in the international market.",
  },
];

function Main({ faqs }) {
  return (
    <div className="faq_main">
      <svg
        className="leftsvg"
        xmlns="http://www.w3.org/2000/svg"
        width="476"
        height="493"
        viewBox="0 0 476 493"
        fill="none"
      >
        <path
          d="M247.5 39.993C325.434 64.5198 408.343 89.9086 446.504 149.015C486.381 210.778 485.077 286.655 447.117 349.171C411.403 407.989 332.813 437.167 256.513 461.913C182.794 485.822 104.31 504.225 28.4532 484.955C-51.7304 464.586 -122.68 419.632 -153.758 357.69C-183.996 297.424 -156.765 232.551 -128.761 171.613C-99.8341 108.668 -71.5419 37.4185 5.86649 10.3402C83.3652 -16.7697 168.765 15.2141 247.5 39.993Z"
          fill="#02320A"
          fillOpacity="0.03"
        />
      </svg>
      <svg
        className="rightsvg"
        xmlns="http://www.w3.org/2000/svg"
        width="387"
        height="572"
        viewBox="0 0 387 572"
        fill="none"
      >
        <path
          d="M459.261 17.902C354.761 -38.098 -109.239 35.902 23.7615 259.402C156.761 482.902 23.761 507.902 223.261 561.902C422.762 615.902 678.761 428.902 684.761 309.902C690.761 190.902 563.762 73.902 459.261 17.902Z"
          fill="#02320A"
          fillOpacity="0.03"
        />
      </svg>
      <div className="faq_main_container">
        <div className="heading">
          <p className="title">Frequently Asked Questions.</p>
          <p className="desc">
            Quick answers to the questions you may have. Don’t find what you are
            looking for ? Feel free to <Link to="/contact">contact us</Link>.
          </p>
        </div>
        <div className="contents">
          {faqs?.map((item, i) => {
            return <DropdownFaq key={i} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Main;
