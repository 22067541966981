import React from "react";
import { motion } from "framer-motion";

import "./Hero.css";

function Hero() {
  return (
    <div className="about_hero">
      <div className="about_hero_container">
        <motion.p
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="about_hero_text"
        >
          About Us
        </motion.p>
        <motion.h3
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="about_hero_title"
        >
          Dirghayu Nepal is here to serve the welfare of children, the elderly,
          and those who are disabled or vulnerable
        </motion.h3>
        {/* <motion.p
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="about_hero_desc"
        >
          We promote Brand Nepal on a Global Scale through Digital Marketing
        </motion.p> */}
      </div>
    </div>
  );
}

export default Hero;
