import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";

import "./Hero.css";

function Hero({ setSearchTerm, searchTerm }) {
  const [input, setInput] = useState("");
  return (
    <div className="faq_hero">
      <div className="faq_hero_container">
        <div className="top">
          <p className="title">FAQs</p>
          <p className="heading">You've got questions?</p>
          <p className="heading">We’ve got answers!</p>
          <p className="desc">
            Everything you need to know about the gurkha connection and its
            works.
          </p>
        </div>
        <form
          className="bottom"
          onSubmit={(e) => {
            e.preventDefault();
            setSearchTerm(e.target.search.value);
          }}
        >
          <BiSearch />
          <input
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            type="text"
            name="search"
            placeholder="Search"
          />
          <AiOutlineClose
            className={`close ${searchTerm !== "" ? "active" : ""}`}
            onClick={() => {
              setSearchTerm("");
              setInput("");
            }}
          />
        </form>
      </div>
    </div>
  );
}

export default Hero;
