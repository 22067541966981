import React from "react";
import "./SideContainer.css";
import { Link } from "react-router-dom";
import { BUCKET_URL } from "../../Home/Partner/Partner";

function SideContainer({ partner, similarProfiles }) {
  return (
    <div className="side_container">
      <div className="side_container_tags">
        <div className="side_container_tag_title">
          <p>Overview</p>
        </div>
        <p className="desc">{partner?.overview}</p>
      </div>
      <div className="side_container_similar">
        <div className="side_container_tag_title">
          <p>Similar</p>
        </div>
        <div className="side_container_similar_contents">
          {similarProfiles?.map((el, i) => {
            return (
              <div className="similar_profile" key={i}>
                <img src={`${BUCKET_URL}/programs/${el?.images.at(0)}`} alt="profile" />
                <div className="similar_profile_content">
                  <div>
                    <p className="title">{el?.title}</p>
                    <p className="tag">{el.tag ? el.tag[0] : ""}</p>
                  </div>
                  <Link to={`/profile/${el._id}`} className="view">
                    <p>View</p>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SideContainer;
