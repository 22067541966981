import React from "react";
import "./CenterContainer.css";
import { BUCKET_URL } from "../../Home/Partner/Partner";

function CenterContainer({ partner }) {
  return (
    <div className="center_container">
      <div className="center_container_upper">
        <p className="desc">{partner?.long_des}</p>
        {partner?.long_des1 && <p className="desc">{partner?.long_des1}</p>}

        {partner.key_highlights && <p className="title">Key Highlights</p>}
        {partner.key_highlights?.map((el, i) => (
          <main key={i}>
            <p className="service_title">{el.topic}</p>
            <p className="service_desc">{el.description}</p>
          </main>
        ))}
        {partner.impact && <p className="title">Impact</p>}
        <p className="desc">{partner.impact}</p>
      </div>
      <div className="center_container_lower">
        {partner?.images.slice(1)?.map((gallery, i) => {
          return (
            <div className="service_card_container" key={i}>
              <img src={`${BUCKET_URL}/programs/${gallery}`} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CenterContainer;
